<template>
  <div class="my-5" :class="col ? `col-lg-${col}` : ''">
    <div class="single_file_input_wrapper">
      <label :for="identifier" class="input_label" v-if="placeholder">
        <div class="select_file_btn">
          <i class="fal fa-paperclip"></i>
          {{ placeholder }}
          <span class="text-danger" v-if="required"> * </span>
        </div>
        <div class="selected_file_name" v-if="file.name">
          {{file.name}}
          <img
            v-if="accept.includes('image')"
            class="d-block mt-3 w-100 h-auto object-fit-cover rounded-4"
            :src="file.path"
            alt="image preview"
            width="200"
            height="200"
            loading="lazy"
          />
        </div>
        <div class="selected_file_name" v-else-if="preSelectedFile">
          <a 
            :href="preSelectedFile"
            target="_blank"
            v-if="accept.includes('pdf')"
          >
            <img
              class="d-block mt-3"
              src="@/assets/media/icons/ui_icons/pdf.png"
              alt="pfd preview"
              width="120"
              height="120"
              loading="lazy"
            />
          </a>

          <template v-else>
            {{preSelectedFile}}
            <img
              v-if="accept.includes('image')"
              class="d-block mt-3 w-100 h-auto object-fit-cover rounded-4"
              :src="preSelectedFile"
              alt="image preview"
              width="200"
              height="200"
              loading="lazy"
            />
          </template>
        </div>
      </label>

      <input
        type="file"
        :accept="accept"
        class="form-control"
        :id="identifier"
        @change="selectFileToUpload"
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script>
import predictFileType from "@/utils/predictFileType.js";

export default {
  name: "BaseFileUploadInput",

  emits: ["selectFile"],

  props: {
    // ====== Start:: General Inputs Props ====== //
    col: {
      required: false,
      type: String,
    },
    identifier: {
      required: true,
      type: String,
    },
    preSelectedFile: {
      required: false,
      type: String,
    },
    accept: {
      type: String,
      required: false,
      default: ".pdf"
    },
    placeholder: {
      required: false,
      type: String,
    },
    required: {
      required: false,
      type: Boolean,
      default: false,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    // ====== End:: General Inputs Props ====== //
  },

  data() {
    return {
      file: {
        name: null,
        path: null,
        file: null,
      },
    };
  },

  methods: {
    // Start:: Select File
    selectFileToUpload(e) {
      if (this.accept.includes("pdf") && predictFileType(e.target.files[0].name) !== "pdf_file") {
        this.$message.error(this.$t("FORMS.Validations.selectedFileMustBePdf"));
        return;
      } else if (this.accept.includes("video") && predictFileType(e.target.files[0].name) !== "video_file") {
        this.$message.error(this.$t("FORMS.Validations.selectedFileMustBeVideo"));
        return;
      } else if (this.accept.includes("image") && predictFileType(e.target.files[0].name) !== "image_file") {
        this.$message.error(this.$t("FORMS.Validations.selectedFileMustBeImage"));
        return;
      } else {
        this.file.name = e.target.files[0].name;
        this.file.path = URL.createObjectURL(e.target.files[0]);
        this.file.file = e.target.files[0];
        this.$emit("selectFile", {
          identifier: this.identifier,
          ...this.file,
        });
        // console.log("SELECTED =>", this.file);
      }
    },
    // End:: Select File
  },
};
</script>
