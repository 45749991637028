<template>
  <nav id="navbar">
    <div class="container p-0">
      <div class="navbar_wrapper">
        <!-- Start:: Search Input -->
        <form class="search_input_wrapper" @submit.prevent="globalSystemSearch">
          <input
            type="text"
            :placeholder="$t('FORMS.Placeholders.search')"
            v-model="searchKeyword"
          />
          <button><i class="fal fa-search"></i></button>
        </form>
        <!-- End:: Search Input -->

        <!-- Start:: Navbar Buttons -->
        <div class="navbar_btns_wrapper">
          <div class="group">
            <!-- ********** Start:: Theme Switcher Button ********** -->
            <div class="theme_changer">
              <v-switch
                class="m-0"
                color="#147CCC"
                inset
                hide-details
                :append-icon="getAppTheme === 'light_theme' ? '☀️' : '🌕'"
                v-model="appTheme"
                value="dark_theme"
                @change="
                  changeTheme(
                    getAppTheme === 'light_theme'
                      ? 'light_theme'
                      : 'light_theme'
                  )
                "
                @click:append="changeTheme(getAppTheme)"
              />
            </div>
            <!-- ********** End:: Theme Switcher Button ********** -->

            <!-- ********** Start:: Notification Button ********** -->
            <div class="user_notification_content_wrapper">
              <!-- <a-badge
                :count="notificationsData.unreadNotifications"
                :overflow-count="9"
              >
                <button
                  aria-label="notification_btn"
                  class="notification_btn"
                  @click.stop="
                    toggleNotificationsMenu();
                    getNotifications();
                  "
                >
                  <i class="fal fa-bell"></i>
                </button>
              </a-badge> -->

              <transition name="fadeInUp">
                <!-- START:: NOTIFICATIONS LIST -->
                <div
                  class="notifications_menu_wrapper"
                  v-if="notificationsMenuIsOpen"
                >
                  <!-- ========== START:: NOTIFICATION HEADER ========== -->
                  <div class="notifications_menu_header">
                    <h5>
                      {{ $t("TITLES.notifications") }}
                    </h5>
                    <h3>
                      {{ notificationsData.unreadNotifications }}
                      {{ $t("TITLES.new") }}
                    </h3>
                  </div>
                  <!-- ========== END:: NOTIFICATION HEADER ========== -->

                  <!-- START:: EMPTY NOTIFICATIONS MESSAGE -->
                  <div
                    class="empty_image"
                    v-if="notificationsData.notificationsList.length == 0"
                  >
                    <img
                      src="../../assets/media/empty_messages/snooze.png"
                      alt="Empty Notifications"
                      width="120"
                      height="120"
                    />
                  </div>
                  <!-- END:: EMPTY NOTIFICATIONS MESSAGE -->

                  <ul class="notifications_menu" v-else>
                    <!-- START:: SINGLE NOTIFICATION -->
                    <li
                      class="notifications_menu_item"
                      v-for="item in notificationsData.notificationsList"
                      :key="item.id"
                    >
                      <!-- START:: NOTIFICATION BODY -->
                      <div
                        class="notification_body_wrapper"
                        @click="
                          redirectNotification(item.notify_type);
                          readSingleNotification(item.id);
                        "
                      >
                        <p class="notification_title">
                          {{ item.title }}
                        </p>
                        <p class="notification_body">
                          {{ item.body }}
                        </p>
                        <p class="notification_date">
                          {{ item.created_at }}
                        </p>
                      </div>
                      <!-- END:: NOTIFICATION BODY -->

                      <!-- START:: DELETE NOTIFICATION BUTTON -->
                      <div class="delete_btn_wrapper">
                        <button
                          class="delete_notification_btn"
                          @click.stop="
                            deleteNotification({
                              id: item.id,
                              notificationType: 'notifications_menu',
                            })
                          "
                        >
                          <i class="fal fa-trash-alt"></i>
                        </button>
                      </div>
                      <!-- END:: DELETE NOTIFICATION BUTTON -->
                    </li>
                    <!-- END:: SINGLE NOTIFICATION -->
                  </ul>

                  <router-link
                    class="all_notifications_route"
                    to="/all-notifications"
                  >
                    {{ $t("BUTTONS.allNotification") }}
                  </router-link>
                </div>
                <!-- END:: NOTIFICATIONS LIST -->
              </transition>
            </div>
            <!-- ********** End:: Notification Button ********** -->
          </div>

          <div class="group">
            <!-- ********** Start:: User Profile Button -->
            <div class="user_profile_menu_wrapper">
              <button
                class="user_profile_menu_btn"
                @click="userProfileModalIsOpen = true"
              >
                <div class="avatar_wrapper">
                  <img
                    src="@/assets/media/images/user_avatar.png"
                    width="25"
                    height="25"
                    :alt="getAuthenticatedUserData.name"
                    loading="lazy"
                  />
                </div>

                <div class="name_wrapper">
                  <h3>{{ getAuthenticatedUserData.name }}</h3>
                </div>
              </button>
            </div>

            <UserDataModal
              :modalIsOpen="userProfileModalIsOpen"
              @toggleModal="userProfileModalIsOpen = !userProfileModalIsOpen"
            />
            <!-- ********** End:: User Profile Button -->

            <!-- ********** Start:: Small Screens Navbar Drawer Button ********** -->
            <button
              class="small_screens_navbar_toggeler"
              @click="$emit('fireToggleNavDrawerEmit')"
            >
              <i class="fal fa-bars"></i>
            </button>
            <!-- ********** End:: Small Screens Navbar Drawer Button ********** -->
          </div>
        </div>
        <!-- End:: Navbar Buttons -->
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import UserDataModal from "@/components/modals/UserDataModal.vue";

export default {
  name: "TheHeader",

  components: {
    UserDataModal,
  },

  emits: ["fireToggleNavDrawerEmit"],

  computed: {
    // Start:: Vuex Getters
    ...mapGetters({
      getAppTheme: "AppThemeModule/getAppTheme",
      getAuthenticatedUserData: "AuthenticationModule/getAuthenticatedUserData",
      notificationsData: "NotificationsModule/notificationsData",
    }),
    // End:: Vuex Getters
  },

  data() {
    return {
      // Start:: Notifications Menu Control Data
      notificationsMenuIsOpen: false,
      // End:: Notifications Menu Control Data

      // Start:: User Profile Modal Controlling Data
      userProfileModalIsOpen: false,
      // End:: User Profile Modal Controlling Data

      // Start:: Search Keyword Data
      searchKeyword: null,
      // End:: Search Keyword Data

      // Start:: App Theme Data "For V-switch"
      appTheme: localStorage.getItem("family_medicine_guide_dashboard_theme"),
      // End:: App Theme Data "For V-switch"
    };
  },

  methods: {
    // Start:: Vuex Actions
    ...mapActions({
      changeAppTheme: "AppThemeModule/changeAppTheme",
      getNotifications: "NotificationsModule/getNotifications",
      deleteNotification: "NotificationsModule/deleteNotification",
      readSingleNotification: "NotificationsModule/readSingleNotification",
    }),
    // End:: Vuex Actions

    // Start:: Toggle Notifications Menu
    toggleNotificationsMenu() {
      this.notificationsMenuIsOpen = !this.notificationsMenuIsOpen;
      this.chatsDrawerIsOpen = false;
    },
    // End:: Toggle Notifications Menu

    // Start:: Notification Redirect
    redirectNotification(notifyType) {
      if (notifyType == "new_user_register") {
        this.$router.push("/clients/all");
      } else if (
        notifyType == "add_shipment_attach" ||
        notifyType == "update_shipment_request" ||
        notifyType == "new_shipment_request"
      ) {
        this.$router.push("/shipment/all");
      } else if (notifyType == "new_authorization_for_user") {
        this.$router.push("/authorizations/all");
      }
    },
    // End:: Notification Redirect

    // Start:: Handling Global System Search
    async globalSystemSearch() {},
    // End:: Handling Global System Search

    // Start:: Handling App Theme
    changeTheme(newTheme) {
      this.appTheme = newTheme;
      setTimeout(() => {
        this.changeAppTheme();
      }, 100);
    },
    // End:: Handling App Theme
  },

  created() {
    // Start:: Fire Methods
    this.getNotifications();
    window.addEventListener("click", () => {
      this.notificationsMenuIsOpen = false;
    });
    // End:: Fire Methods
  },
};
</script>
