<template>
  <div class="state-list">
             
                <ul>
                  <template v-if="users.length">
                    <li v-for="(item,index) in users" :key="item.id">
                        <img :src="item.user.image">
                        <div>
                          <p v-text="item.name">
                            
                          </p>
                          <h5 v-text="item.score + '/' + (item.questions_number * 10)"></h5>
                        </div>
                        <span v-text="'# ' + index "></span>
                  </li>
                  </template>
                  <template v-else>
                    <li>
                    <img src="@/assets/media/images/user_avatar.png">
                    <div>
                      <p>
                        Dr.Osama Kamal hamed
                      </p>
                      <h5>80/100</h5>
                    </div>
                    <span>#1</span>
                  </li>
                  <li>
                    <img src="@/assets/media/images/user_avatar.png">
                    <div>
                      <p>
                        Dr.Osama Kamal hamed
                      </p>
                      <h5>80/100</h5>
                    </div>
                    <span>#2</span>
                  </li>
                  <li>
                    <img src="@/assets/media/images/user_avatar.png">
                    <div>
                      <p>
                        Dr.Osama Kamal hamed
                      </p>
                      <h5>80/100</h5>
                    </div>
                    <span>#3</span>
                  </li>
                  <li>
                    <img src="@/assets/media/images/user_avatar.png">
                    <div>
                      <p>
                        Dr.Osama Kamal hamed
                      </p>
                      <h5>80/100</h5>
                    </div>
                    <span>#4</span>
                  </li>
                  <li>
                    <img src="@/assets/media/images/user_avatar.png">
                    <div>
                      <p>
                        Dr.Osama Kamal hamed
                      </p>
                      <h5>80/100</h5>
                    </div>
                    <span>#5</span>
                  </li>
                  </template>
                </ul>
</div>
</template>


<script>
export default {
name: "UsersState",
props: {
  users: {
    type: Array,
    required: true,  },
},
}
</script>

<style lang="scss" scoped>
.state-list ul{
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.state-list ul li{
  box-shadow: 0px 4px 6px #136FB721;
  background: #F4F7FB 0% 0% no-repeat padding-box;
  padding: 5px;
  border-radius: 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.state-list ul li img{
    max-width: 40px;
    max-height: 40px;
    margin: auto 0;
}

.state-list ul li div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.state-list ul li div p{
  display: inline-block;
}
.state-list ul li div h5{
  text-align: center;
}
.state-list ul li  span{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;
    color: #ADBDD2;
    font: normal normal normal 25px/32px Overpass;
}
</style>