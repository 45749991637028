<template>
  <div class="circles-container">
    <div class="circle_chart" :class="{hasTopColor:allHasTopColor,hasRightColor:allHasRightColor,hasBottomColor:allHasBottomColor,hasLeftColor:allHasLeftColor}">
        <p v-text="answers.allAnswered + '%'"></p>
    </div>
    <p style="text-align:center;color:#617087;font-size:10px;margin:0"> Answered Questions</p>
    <div class="circle_chart">
        <p v-text="answers.allSaved + '%'" :class="{hasTopColor:savedHasTopColor,hasRightColor:savedHasRightColor,hasBottomColor:savedHasBottomColor,hasLeftColor:savedHasLeftColor}"></p>
    </div>
    <p style="text-align:center;color:#617087;font-size:10px;margin:0">  Saved Questions</p>
    <div class="circle_chart" :class="{hasTopColor:inCorrectHasTopColor,hasRightColor:inCorrectHasRightColor,hasBottomColor:inCorrectHasBottomColor,hasLeftColor:inCorrectHasLeftColor}">
      <p v-text="answers.inCorrect + '%'"></p>
    </div>
    <p style="text-align:center;color:#617087;font-size:10px;margin:0"> Incorrect  Questions</p>
  </div>
</template>
<script>
export default {
  name: "CircleChart",
  computed:{
      allHasTopColor(){
        return this.answers.allAnswered > 1 ? true : false; 
      },
      allHasRightColor(){
        return this.answers.allAnswered > 25 ? true : false; 
      },
      allHasBottomColor(){
        return this.answers.allAnswered > 50 ? true : false; 
      },
      allHasLeftColor(){
        return this.answers.allAnswered > 75 ? true : false; 
      },

      savedHasTopColor(){
        return this.answers.allSaved > 1 ? true : false; 
      },
      savedHasRightColor(){
        return this.answers.allSaved > 25 ? true : false; 
      },
      savedHasBottomColor(){
        return this.answers.allSaved > 50 ? true : false; 
      },
      savedHasLeftColor(){
        return this.answers.allSaved > 75 ? true : false; 
      },

      inCorrectHasTopColor(){
        return this.answers.inCorrect > 1 ? true : false; 
      },
      inCorrectHasRightColor(){
        return this.answers.inCorrect > 25 ? true : false; 
      },
      inCorrectHasBottomColor(){
        return this.answers.inCorrect > 50 ? true : false; 
      },
      inCorrectHasLeftColor(){
        return this.answers.inCorrect > 75 ? true : false; 
      },
  },
  props: {
    answers:{
      type:Object,
      required:true
    }
  },
}
</script>

<style scoped>

.circle_chart{
    border: 6px solid #03847D;
    width: 6rem;
    margin: 0 auto;
    border-radius: 100%;
    height: 6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}
.circle_chart p{
    width: 80%;
    height: 80%;
    border-radius: 50%;
    text-align: center;
    background-color: #147CCC;
    color: #F2F5FA;  
    font: normal normal normal 16px/23px Overpass;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}
.circles-container{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.hasRightColor{
  border-right-color: #147CCC;
}
.hasLeftColor{
  border-left-color: #147CCC;
}
.hasTopColor{
  border-top-color: #147CCC;
}
.hasBottomColor{
  border-bottom-color: #147CCC;
}
</style>