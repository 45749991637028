<template>
    <div class="chart_card">
        <!-- START:: TITLE -->
        <h4 class="chart_title m-0">
            <slot name="chart_title"></slot>
        </h4>
        <!-- END:: TITLE -->

        <!-- START:: CHART -->
        <apexchart
            type="radialBar"
            width="200px"
            height="200px"
            :options="radialChartOptions"
            :series="radialChartSeries"
        ></apexchart>
        <!-- END:: CHART -->
    </div>
</template>

<script>
export default {
    name: "RadialChart",

    props: ["ChartLabels", "ChartData"],

    data() {
        return {
            // START:: RADIAL BAR CHART DATA
            radialChartOptions: {
              forColor:"#03847D",

                plotOptions: {
                  forColor:"#03847D",

                    radialBar: {
                        size: undefined,
                        inverseOrder: true,
                        hollow: {
                            margin: 10,
                            size: "40%",
                            // background: "transparent",
                        },
                        track: {
                            show: false,
                        },
                        startAngle: -230,
                        endAngle: 230,
                        total:{
                          color:"#03847D"
                        }
                    },
                    
                },
                stroke: {
                    // lineCap: "round",
                    forColor:"#03847D",

                },
                fillColor:'#03847D',
                colors: ["#147CCC","#03847D"],
                labels: this.ChartLabels,
                legend: {
                    show: false,
                    floating: true,
                    position: "right",
                    offsetX: 0,
                    offsetY: 200,
                },
            },

            radialChartSeries: this.ChartData,
            // END:: RADIAL BAR CHART DATA
        };
    },
};
</script>

<style scoped>
  .chart_card{
    background: none;
    border-radius: 0;
    box-shadow: none;
  }
</style>