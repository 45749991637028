<template>
  <div class="my-5" :class="col ? `col-lg-${col}` : ''">
    <div class="input_wrapper top_label color_picker">
      <span class="custom_input_label"> 
        {{ placeholder }} 
        <span class="text-danger" v-if="required">*</span>
      </span>
      <label
        class="custom_color_preview"
        :for="uniqueInputId"
        :style="{ background: labelBackground }"
      ></label>
      <input
        :id="uniqueInputId"
        type="color"
        :value="value"
        @input="updateValue($event)"
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseColorInput",

  props: {
    // ====== General Inputs Props
    col: {
      required: false,
      type: String,
    },
    value: {
      required: true,
    },
    placeholder: {
      required: true,
      type: String,
    },
    required: {
      required: false,
      type: Boolean,
      default: false,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      // Start:: Unique Input Id
      uniqueInputId: `color_picker_${Date.now()}`,
      // End:: Unique Input Id

      // Start:: Label Background Color
      labelBackground: this.value,
      // End:: Label Background Color
    };
  },

  watch: {
    value(newVal) {
      this.labelBackground = newVal;
    },
  },

  methods: {
    updateValue(e) {
      this.$emit("input", e.target.value);
    },
  },
};
</script>