<template>
  <div class="home_page_wrapper">
    <div class="row" v-if="hasHomePermission">
      <div class="col-8">
        <div class="row">
          <div class="col-7 card">
            <div class="state-list">
                <h4 class="state-heading">
                  <img src="@/assets/media/illustrations/chart.svg">
                  &nbsp;
                   Website Visits Chart 
                </h4>
                <AreaChart :ChartLabels="chartLabels" :ChartData="chartValues" />
                <div class="visits_count">
                <p>
                  <span> total visits</span> 
                  <span style="color: #147CCC;" class="count">
                    {{kFormatter(totalVisits)}}
                  </span>
                </p>
                <p>
                  <span> lowest visits</span> 
                  <span class="count" style="color: #FF7C9F;">
                    {{ kFormatter(lowestVisits) }}
                  </span>
                </p>
                <p>
                  <span> highest visits</span> 
                  <span class="count" style="color: #F9D967;">
                    {{ kFormatter(highestVisits) }}
                  </span>
                </p>
                <p>
                  <span> average visits</span> 
                  <span class="count" style="color: #F96767;">
                    {{ kFormatter(averageVisits) }}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="col-4 card">
            <h4 class="state-heading">
                  <img src="@/assets/media/illustrations/chart.svg">
                  &nbsp;
                   Top MCQs Grades 
              </h4>
              <UsersState :users="usersState" />
              
          </div>
        </div>
        <div class="row">
            <div class="col-4 card">
              <div class="state-list" style="height: 88%;">
                <h4 class="state-heading">
                  <img src="@/assets/media/illustrations/book.svg">
                  &nbsp;
                   Protocols visits 
                </h4>
                <ul class="progress">
                  <template v-if="protocolsVisits.length">
                    <li v-for="protocol in protocolsVisits" :key="protocol.id"> 
                      <p :for="protocol.protocol_name" v-text="protocol.protocol_name" style="width:40%;text-wrap: wrap;"></p>
                      <progress :id="protocol.protocol_name" :value="protocol.views_count" max="100"></progress>
                      <span v-text="protocol.views_count+' %'"></span>
                    </li>
                  </template>

                  <template v-else>
                    <li>
                    <p for="lorem">Lorem ipsum</p>
                    <progress id="lorem" value="90" max="100"></progress>
                    <span>90%</span>
                  </li>
                  <li>
                    <p for="lorem">Lorem ipsum</p>
                    <progress id="lorem" value="90" max="100"></progress>
                    <span>90%</span>
                  </li>
                  <li>
                    <p for="lorem">Lorem ipsum</p>
                    <progress id="lorem" value="90" max="100"></progress>
                    <span>90%</span>
                  </li>
                  <li>
                    <p for="lorem">Lorem ipsum</p>
                    <progress id="lorem" value="90" max="100"></progress>
                    <span>90%</span>
                  </li>
                  <li>
                    <p for="lorem">Lorem ipsum</p>
                    <progress id="lorem" value="90" max="100"></progress>
                    <span>90%</span>
                  </li>
                  <li>
                    <p for="lorem">Lorem ipsum</p>
                    <progress id="lorem" value="90" max="100"></progress>
                    <span>90%</span>
                  </li>
                  <li>
                    <p for="lorem">Lorem ipsum</p>
                    <progress id="lorem" value="90" max="100"></progress>
                    <span>90%</span>
                  </li>
                  <li>
                    <p for="lorem">Lorem ipsum</p>
                    <progress id="lorem" value="90" max="100"></progress>
                    <span>90%</span>
                  </li>
                  </template>
                </ul>
              </div>

            </div>
            <div class="col-3 card">
                <CircleChart :answers="answers" />
            </div>

            <div class="col-4 card">
              <div class="state-list" style="height: 100%;">
                <h4 class="state-heading">
                  <img src="@/assets/media/illustrations/book.svg">
                  &nbsp;
                   Book top visits 
                </h4>
                <table>
                  <thead>
                    <tr>
                      <th>Section</th>
                      <th>Visits Counter</th>
                      <th>Saved parts</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in sectionsVisits" :key="item.id">
                      <td v-text="item.name"></td>
                      <td v-text="item.visits_count"></td>
                      <td v-text="item.saved_count"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
        </div>
      </div>
      <div class="col-4">
          <StateCard  v-for="state in states" :key="state.id" :icon="state.icon" :text="state.text" :count="state.count"  :up="state.up" /> 
      </div>
    </div>
  </div>
</template>

<script>
// Start:: Importing Charts
import SparksAreaCharts from "@/components/charts/SparksAreaCharts.vue";
import ColumnChart from "@/components/charts/ColumnChart.vue";
import DonutChart from "@/components/charts/DonutChart.vue";
import LineChart from "@/components/charts/LineChart.vue";
import RadialChart from "@/components/charts/RadialChart.vue";
import StateCard from "@/components/ui/cards/StateCard.vue";
import CircleChart from "@/components/ui/cards/CircleChart.vue";
import UsersState from "@/components/ui/cards/UsersState.vue";
import AreaChart from "@/components/charts/AreaChart.vue";
// End:: Importing Charts
import { mapGetters, mapActions } from "vuex";

export default {
  name: "HomePage",
  computed: {
    // Start:: Vuex Get Authenticated User Data
    ...mapGetters({
      getAuthenticatedUserData: "AuthenticationModule/getAuthenticatedUserData",
      authedUserPermissions: "PermissionsModule/authedUserPermissions",
    }),
    // End:: Vuex Get Authenticated User Data
  },
  components: {
    SparksAreaCharts,
    ColumnChart,
    RadialChart,
    DonutChart,
    LineChart,
    StateCard,
    UsersState,
    AreaChart,
    CircleChart
  },
  data(){
    return{
      pageIsLoading:false,
      states:[
      {id:1,"icon":"fal fa-user","text":"Active user now","count":"600","up":true},
      {id:2,"icon":"fal fa-download","text":"Downloads count","count":"700","up":false},
      {id:3,"icon":"fal fa-newspaper","text":"Articles count","count":"360","up":true},
      {id:4,"icon":"fal fa-question","text":"MCQs Solving count","count":"910","up":false},
      {id:5,"icon":"fal fa-folders","text":"Protocols count","count":"200","up":false},
      {id:6,"icon":"fal fa-clone","text":"Protocols Downloads","count":"80","up":true},
      {id:7,"icon":"fal fa-video","text":"Lectures Views","count":"2000","up":true},
      ],
      chartLabels:["sat","sun","mon","tue","wen","thu","fri"],
      chartValues:[20,50,12,31,10,33,50],
      totalVisits:1250,
      lowestVisits:210,
      highestVisits:764,
      averageVisits:250,
      usersState:[],
      protocolsVisits:[],
      answers:{
        allAnswered:"60",
        allSaved:"30",
        inCorrect:"20"
      },
      sectionsVisits:[
        {
        id: 11,
        name:"Section 1-Family Medicine",
        saved_count: 8,
        visits_count: 437
       }
      ],
      hasHomePermission:false
    }
  },
  methods: {
    // START:: FILTER STATISTICS
    filterSalesAnalyses(filterValue, transFilterValue) {
      this.selectedSalesFilter = transFilterValue;
    },
    filterOrders(filterValue, transFilterValue) {
      this.selectedOrdersFilter = transFilterValue;
    },
    filterTransactions(filterValue, transFilterValue) {
      this.selectedTransactionFilter = transFilterValue;
    },
    filterOrdersRate(filterValue, transFilterValue) {
      this.selectedOrdersRateFilter = transFilterValue;
    },
    // END:: FILTER STATISTICS
    async setPageData() {
      this.pageIsLoading = true;
      try {
        let res = await this.$axios({
          method: "GET",
          url: "home",
        });
        this.pageIsLoading = false;
        // console.log(res.data.data);
       this.chartLabels     = res.data.data.chart_section.labels;
       this.chartValues     = res.data.data.chart_section.values;
       this.totalVisits     = res.data.data.chart_section?.total_visits;
       this.lowestVisits    = res.data.data.chart_section?.lowest_visits?.visit_count  | 0;
       this.highestVisits   = res.data.data.chart_section?.highest_visits?.visit_count | 0;
       this.averageVisits   = res.data.data.chart_section?.average_visits.visit_count | 0;
       this.usersState      = res.data.data.mcq_sections;
       this.protocolsVisits = res.data.data.protocols_visits;
       this.answers         = res.data.data.circle_chart;
       this.sectionsVisits  = res.data.data.book_visits;
       this.states          = res.data.data.cards_data;
      } catch (error) {
        this.pageIsLoading = false;
        console.log(error.response.data.message);
      }
    },

    kFormatter(num) {
      return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
    },
    ...mapActions({
      getAuthedUserPermissions: "PermissionsModule/getAuthedUserPermissions",
    }),

   async getPermission(){
      if(this.getAuthenticatedUserData.type == 'super_admin'){
          this.hasHomePermission = true;
      }else{
        await this.getAuthedUserPermissions();
        const getPermission = this.authedUserPermissions.find(permission => permission.name === 'Home');
        this.hasHomePermission =   getPermission ? true :false;
      }

    }
  },

  mounted() {
    // START:: FIRE METHODS
    this.setPageData();
    this.getPermission();
    // END:: FIRE METHODS
  },
};
</script>

<style lang="scss" scoped>
.home_page_wrapper {
  position: relative;
}


.card{
  background-color: #fff;
  margin: 10px 5px;
  min-height: 400px;
  box-shadow: 5px 5px 5px #c1c1c1ba;
  border-radius: 25px;
  border: 0;
}

.state-heading{
  font: normal normal normal 18px/23px Overpass;
  color: #147CCC;
  margin-bottom: 10px;
}
.visits_count{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 10px;
}
.visits_count p{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 10px;
}

.visits_count p span.count{
  font-size: 23px;
}
.progress{
  display: flex;
  list-style: none;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  background-color: #fff;
}

.progress li{
  display: flex;
  list-style: none;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
}

.progress li progress{
  height: 50%;
  border-radius: 20px;
}
.progress li progress::-webkit-progress-bar{
  background-color: #ffff;
}
.progress li:nth-child(odd) progress::-webkit-progress-value{
  height: 70%;
  background-color: #147CCC;
}
.progress li:nth-child(even) progress::-webkit-progress-value{
  height: 70%;
  background-color: #03847D;
}
table{
  width: 100%;
  height: 80%;
  margin-left: 10px;
}
table thead{
  color: #147CCC;
}
table tbody tr td{
  color: #0A2C75;
}
table tr{
  border-radius: 8px;
}
table tr:nth-child(even){
  background-color: #F4F7FB;
}

</style>