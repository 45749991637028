export default {
  // Start:: Countries Getter
  allCountries(state) {
    return state.countries;
  },
  // End:: Countries Getter

  // Start:: Cities Getter
  allCitiesByCountryIdData(state) {
    return state.citiesByCountryId;
  },
  // End:: Cities Getter

  // Start:: Books Getter
  allBooks(state) {
    return state.books;
  },
  // End:: Books Getter

  // Start:: Books Sections
  sectionsByBookId(state) {
    return state.sectionsByBookId;
  },
  // End:: Books Sections

  // Start:: Section Chapters
  chaptersBySectionId(state) {
    return state.chaptersBySectionId;
  },
  // End:: Section Chapters
  
  // Start:: Chapter Lessons
  lessonsByChapterId(state) {
    return state.lessonsByChapterId;
  },
  // End:: Chapter Lessons


  // Start:: Roles Getter
  allRoles(state) {
    return state.roles;
  },
  // End:: Roles Getter

  allCategories(state) {
    return state.categories;
  },

  allFlashCategories(state) {
    return state.flashCategories;
  },
};
