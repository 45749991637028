<template>
  <div class="user_empty_message_wrapper">
    <!-- Start:: Empty Message Image -->
    <div class="image_wrapper">
      <img
        class="message_image"
        :src="messageImage"
        width="100"
        height="100"
        alt="empty data message"
        loading="lazy"
      >
    </div>
    <!-- End:: Empty Message Image -->

    <!-- Start:: Empty Message Body -->
    <div class="message_body_wrapper">
      <h4 class="message_text"> {{ messageText }} </h4>
      <slot name="message_btns"></slot>
    </div>
    <!-- End:: Empty Message Body -->
  </div>
</template>

<script>
export default {
  name: "EmptyDataMessage",

  props: {
    messageImage: {
      type: String,
      required: true
    },
    messageText: {
      type: String,
      required: true
    },
  },
};
</script>

<style lang="scss" scoped>
.user_empty_message_wrapper {
  min-height: calc(100vh - 170px);
  .message_body_wrapper {
    margin-top: 25px;
    @include flexCenterAlignment;
    flex-direction: column;
    .message_text {
      color: var(--theme_soft_text_clr);
      font-size: 22px;
    }
    a {
      @include secondaryBtnStyle;
      margin-top: 2px;
      width: unset;
      font-size: 16px;
      border-radius: 18px;
    }
  }
}
</style>