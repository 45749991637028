<template>
    <div class="stat-card col-9">            
      <span>
        <i :class="icon"></i>
      </span>
      <div class="text">
        
          <div class="">
            <h6 v-text="text"></h6>
            <span v-text="count"></span>
          </div>
          <i class="fas fa-caret-up" v-if="up"></i>
          <i class="fas fa-caret-down" style="color: red;" v-else></i> 
        </div>
    </div>
</template>


<script>
export default {
  name: "StateCard",
  props: {
    icon: {
      type: String,
      required: true,
      default:"fal fa-user"
    },
    text: {
      type: String,
      required: false,
      default:"Active users now"
    },
    count: {
      type: String,
      required: false,
      default:"0"
    },
    up: {
      type:Boolean,
      required: false,
      default:true
    }
  },
}
</script>

<style lang="scss" scoped>
  .stat-card{
  background-color: #fff;
  border-radius: 25px;
  padding: 10px;
  height: 100px;
  width:100%;
  box-shadow: 5px 5px 5px #c1c1c1ba;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.stat-card > span{
  display: inline-block;
  width: 30%;
  text-align: center;
  min-height: 100%;
  font: normal normal normal 31px/39px Overpass;
  color: #03847D;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.stat-card > div{
  color: #147CCC;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 70%;
  text-align: center;
  min-height: 100%;
  font: normal normal normal 31px/39px Overpass;
}

.stat-card > div > h6 {
  font-weight: bold;
} 
.stat-card > div > span{
  color: #147CCC;
  font-weight:bold;
  // font: normal normal normal 31px/39px Overpass;
  width: 40%;
  display: inline-block;
}

.text{
  display: flex;
  flex-direction: row;
  
}

.text > div {
  width: 70%;
}

.stat-card > div> i{
  font-size: 20px;
  width: 10%;
  display: inline-block;
  color: #48c74e;
}

.down{
  color: #f00;
}

</style>