<template>
  <div class="chart_card">
      <!-- START:: TITLE -->
      <h4 class="chart_title m-0">
          <slot name="chart_title"></slot>
      </h4>
      <!-- END:: TITLE -->

      <!-- START:: CHART -->
      <apexchart
          type="area"
          height="350px"
          :options="barChartOptions"
          :series="barChartSeries"
      ></apexchart>
      <!-- END:: CHART -->
  </div>
</template>

<script>
export default {
  name: "AreaChart", 

  props: ["ChartLabels", "ChartData"],

  data() {

      return {
          // START:: BAR CHART DATA
          barChartOptions: {
              chart: {
                  width: "100%",
                  style:{
                        colors: "#03847D"
                  }
              },
              // fill:{
              //   colors: "#03847D"
              // },
              colors:['#03847D'],
              dataLabels: {
                  enabled: false,
              },
              plotOptions: {
                  bar: {
                      columnWidth: "70%",
                      borderRadius: 5,
                      horizontal: false,
                      style:{
                        colors: "#03847D"
                      }
                  },
              },

              labels: this.ChartLabels,

              xaxis: {
                  labels: {
                      show: true,
                      style: {
                          colors: "#78909c",
                      },
                  },
                  axisBorder: {
                      show: true,
                  },
                  axisTicks: {
                      show: true,
                  },
              },
              yaxis: {
                  axisBorder: {
                      show: true,
                  },
                  axisTicks: {
                      show: false,
                  },
                  labels: {
                      style: {
                          colors: "#78909c",
                      },
                  },
              },
          },

          barChartSeries:[{
            name:'highest value',
            data:this.ChartData
        }],
          // END:: BAR CHART DATA
      };
  },
  methods: {
    // END:: FILTER STATISTICS
    async setPageData() {
      this.pageIsLoading = true;
      try {
        let res = await this.$axios({
          method: "GET",
          url: "chart",
        });
        this.pageIsLoading = false;
        // console.log(res.data.data);
      //  this.chartLabels     = res.data.data.labels;
      this.barChartOptions = {
              chart: {
                  width: "100%",
                  style:{
                        colors: "#03847D"
                  }
              },
              // fill:{
              //   colors: "#03847D"
              // },
              colors:['#03847D'],
              dataLabels: {
                  enabled: false,
              },
              plotOptions: {
                  bar: {
                      columnWidth: "70%",
                      borderRadius: 5,
                      horizontal: false,
                      style:{
                        colors: "#03847D"
                      }
                  },
              },

              labels: res.data.data.labels,

              xaxis: {
                  labels: {
                      show: true,
                      style: {
                          colors: "#78909c",
                      },
                  },
                  axisBorder: {
                      show: true,
                  },
                  axisTicks: {
                      show: true,
                  },
              },
              yaxis: {
                  axisBorder: {
                      show: true,
                  },
                  axisTicks: {
                      show: false,
                  },
                  labels: {
                      style: {
                          colors: "#78909c",
                      },
                  },
              },
          };


       this.barChartSeries     = [{name:"Hightest value",data:res.data.data.values}];
      } catch (error) {
        this.pageIsLoading = false;
        console.log(error.response.data.message);
      }
    },


  },
  mounted() {
    // START:: FIRE METHODS
    this.setPageData();
    // END:: FIRE METHODS
  },
};
</script>
