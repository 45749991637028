<template>
  <div class="my-5" :class="col ? `col-lg-${col}` : ''">
    <!-- ========== Start:: Date Picker Input ========== -->
    <div class="input_wrapper top_label" v-if="type === 'date'">
      <label class="form-label">
        {{ placeholder }}
        <span class="text-danger" v-if="required">*</span>
      </label>
      <a-date-picker
        placeholder=""
        format="YYYY-MM-DD"
        valueFormat="YYYY-MM-DD"
        :value="value"
        :disabled-date="disabledDate"
        @change="updateValue($event)"
        :readonly="readonly"
        :disabled="disabled"
      />
    </div>
    <!-- ========== End:: Date Picker Input ========== -->

    <!-- ========== Start:: Time Picker Input ========== -->
    <div class="input_wrapper top_label time_picker" v-if="type === 'time'">
      <label class="form-label">
        {{ placeholder }}
        <span class="text-danger" v-if="required">*</span>
      </label>
      
      <v-menu
        ref="menu"
        v-model="dateMenuIsOpen"
        :close-on-content-click="false"
        :return-value.sync="value"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="value"
            @change="updateValue($event)"
            label="Picker in menu"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="dateMenuIsOpen"
          full-width
          :value="value"
          format="24hr"
          @change="updateValue($event)"
          @click:minute="updateValue($event)"
        ></v-time-picker>
      </v-menu>
    </div>
    <!-- ========== End:: Time Picker Input ========== -->
  </div>
</template>

<script>
export default {
  name: "BasePickerInput",

  props: {
    // ====== Start:: General Inputs Props ====== //
    col: {
      required: false,
      type: String,
    },
    value: {
      required: true,
    },
    disabledDate: {
      required: false,
    },
    type: {
      required: true,
      type: String,
    },
    placeholder: {
      required: false,
      type: String,
    },
    required: {
      required: false,
      type: Boolean,
      default: false,
    },
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    // ====== End:: General Inputs Props ====== //
  },

  data() {
    return {
      dateMenuIsOpen: false,
    }
  },

  methods: {
    updateValue(e) {
      this.$emit("input", e);
      if(this.type === "time" ) {
        this.$refs.menu.save(e)
      }
    },
  },
};
</script>
