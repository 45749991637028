<template>
  <div class="main_loader_wrapper">
    <lottie-animation
      :class="'lottie_loader'"
      :animationData="require('@/assets/media/loaders/loader.json')"
      :loop="true"
    />
  </div>
</template>

<script>
export default {
  name: "MainLoader",
};
</script>

<style lang="scss" scoped>
.main_loader_wrapper {
  position: absolute;
  width: 100%;
  height: calc(100vh - 180px);
  @include flexCenterAlignment;
  background-color: var(--theme_main_bg_clr);
  z-index: 100;
  .lottie_loader {
    width: 400px;
    height: 400px;
  }
}
</style>
