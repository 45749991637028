<template>
  <div class="data_main_preview_card" :class="{refused:itemIsRefused}">
    <img
      class="card_image"
      :src="cardImage"
      width="85"
      height="85"
      :alt="cardTitle"
      loading="lazy"
    >

    <div class="card_body">
      <h5 class="card_title"> {{cardTitle}} </h5>
      <div class="card_btns">
        <router-link :to="itemShowRoute" class="show_route" v-if="canShow">
          <i class="fal fa-eye"></i>
        </router-link>
        <router-link :to="itemEditRoute" class="edit_route" v-if="canEdit">
          <i class="fal fa-pen"></i>
        </router-link>
        <button class="delete_btn" @click="$emit('fireDelete')" v-if="canDelete">
          <i class="fal fa-trash"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DataMainPreviewCard",

  emits: ["fireDelete"],

  props: {
    id: {
      required: true
    },
    slug: {
      required: false
    },
    uuid: {
      required: false
    },
    dataCategory: {
      type: String,
      required: true
    },
    cardImage: {
      type: String,
      required: true
    },
    cardTitle: {
      type: String,
      required: true
    },
    canShow: {
      type: Boolean,
      required: false,
      default: true,
    },
    canEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
    canDelete: {
      type: Boolean,
      required: false,
      default: true,
    },
    active: {
      type: Number,
      required: false,
      default: 1,
    }
  },

  computed: {
    itemShowRoute() {
      if(this.dataCategory === 'books') {
        return `/${this.dataCategory}/show/${this.id}/${this.slug}`
      } else {
        return `/${this.dataCategory}/show/${this.id}/${this.uuid}`
      }
    },
    itemEditRoute() {
      if(this.dataCategory === 'books') {
        return `/${this.dataCategory}/edit/${this.id}/${this.slug}`
      } else {
        return `/${this.dataCategory}/edit/${this.id}/${this.uuid}`
      }
    },
    itemIsRefused() {
      return this.active === 2;
    }
  }
}
</script>

<style lang="scss" scoped>
.data_main_preview_card {
  min-height: 260px;
  max-height: 260px;
  background-color: var(--theme_secondary_bg_clr);
  padding: 25px;
  @include flexCenterAlignment;
  flex-direction: column;
  justify-content: space-around;
  row-gap: 25px;
  border-radius: 22px;
  @include simpleShadow;
  &.refused {
    background-color: #fa9797;
    .card_body {
      background-color: #fa9797;
      .card_title {
        color: var(--white_clr);
      }

    }
  }

  .card_body {
    .card_title {
      text-align: center;
      font-size: 16px;
      font-family: $semi_bold_font;
      color: var(--theme_text_clr);

    }

    .card_btns {
      margin-top: 12px;
      @include flexCenterAlignment;
      column-gap: 8px;

      .show_route,
      .edit_route,
      .delete_btn {
        width: 32px;
        height: 32px;
        padding: 5px;
        border-radius: 5px;
        @include flexCenterAlignment;
        svg, i {
          color: var(--white_clr);
          font-size: 16px;
        }
      }
      .show_route {
        background-color: var(--secondary_theme_clr);
      }
      .edit_route {
        background-color: var(--main_theme_clr);
      }

      .delete_btn {
        background-color: var(--mid_red_clr);
      }
    }
  }
}
</style>