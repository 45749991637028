import Vue from "vue";

// Start::Global Components Inputs
import MainLoader from "@/components/ui/loaders/MainLoader.vue";
import ImageModal from "@/components/modals/ImageModal.vue";
import DescriptionModal from "@/components/modals/DescriptionModal.vue";
import DataMainPreviewCard from "@/components/ui/cards/DataMainPreviewCard.vue";
import InlineDataPreviewCard from "@/components/ui/cards/InlineDataPreviewCard.vue";
import EmptyDataMessage from "@/components/ui/EmptyDataMessage.vue";
import BaseButton from "@/components/ui/BaseButton.vue";
// End::Global Components Inputs

Vue.component("main-loader", MainLoader);
Vue.component("image-modal", ImageModal);
Vue.component("description-modal", DescriptionModal);
Vue.component("data-main-preview-card", DataMainPreviewCard);
Vue.component("inline-data-preview-card", InlineDataPreviewCard);
Vue.component("empty-data-message", EmptyDataMessage);
Vue.component("base-button", BaseButton);