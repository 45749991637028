
export default {
  // Start:: Set Countries
  setCountries(state, payload) {
    state.countries = payload;
  },
  // End:: Set Countries

  // Start:: Set Cities
  setCitiesByCountryId(state, payload) {
    state.citiesByCountryId = payload;
  },
  // End:: Set Cities

  // Start:: Set Books
  setBooks(state, payload) {
    state.books = payload;
  },
  // End:: Set Books

  // Start:: Set Sections
  setSectionsByBookId(state, payload) {
    state.sectionsByBookId = payload;
  },
  // End:: Set Sections

  // Start:: Set Chapters
  setChaptersBySectionId(state, payload) {
    state.chaptersBySectionId = payload;
  },
  // End:: Set Chapters
  // Start:: Set Lessons
  setLessonsByChapterId(state, payload){
    state.lessonsByChapterId = payload;
  },
    // End:: Set Lessons

  // Start:: Set Roles
  setRoles(state, payload) {
    state.roles = payload;
  },
  // End:: Set Roles

  setCategories(state, payload) {
    state.categories = payload;
  },

  setFlashCategories(state, payload) {
    state.flashCategories = payload;
  }
};
