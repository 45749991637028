<template>
  <div class="text_editor_loader_wrapper">
    <lottie-animation
      :class="'lottie_loader'"
      :animationData="require('@/assets/media/loaders/editor_loader.json')"
      :loop="true"
    />
    <h3> Image Is Being Uploaded </h3>
  </div>
</template>

<script>
export default {
  name: "TextEditorLoader",
};
</script>

<style lang="scss" scoped>
.text_editor_loader_wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  @include flexCenterAlignment;
  flex-direction: column;
  background-color: var(--theme_main_bg_clr);
  border-radius: 15px;
  z-index: 100;
  .lottie_loader {
    width: 200px;
    height: 200px;
  }
  h3 {
    color: var(--main_theme_clr);
    font-size: 23px;
  }
}
</style>
