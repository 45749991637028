
export default {
    // START:: SET AUTHENTICATED USER DATA
    setAuthenticatedUserData(state, payload) {
        if (payload.id) {
            state.userId = payload.id;
            localStorage.setItem("family_medicine_guide_dashboard_user_id", payload.id);
        }
        if (payload.token) {
            state.userToken = payload.token;
            localStorage.setItem(
                "family_medicine_guide_dashboard_user_token",
                payload.token
            );
        }
        if (payload.type) {
            state.userType = payload.type;
            localStorage.setItem(
                "family_medicine_guide_dashboard_user_type",
                payload.type
            );
        }
        if (payload.name) {
            state.userName = payload.name;
            localStorage.setItem(
                "family_medicine_guide_dashboard_userName",
                payload.name
            );
        }
        if (payload.email) {
          state.email = payload.email;
          localStorage.setItem(
            "family_medicine_guide_dashboard_email",
            payload.email
          );
        }
        if (payload.avatar) {
            state.userAvatar = payload.avatar;
            localStorage.setItem(
                "family_medicine_guide_dashboard_user_avatar",
                payload.avatar
            );
        }
        if (payload.permissions) {
          state.permissions = payload.permissions;
          localStorage.setItem(
              "family_medicine_guide_dashboard_permissions",
              payload.permissions
          );
      }
    },
    // END:: SET AUTHENTICATED USER DATA
};
