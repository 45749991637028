var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{attrs:{"id":"navbar"}},[_c('div',{staticClass:"container p-0"},[_c('div',{staticClass:"navbar_wrapper"},[_c('form',{staticClass:"search_input_wrapper",on:{"submit":function($event){$event.preventDefault();return _vm.globalSystemSearch.apply(null, arguments)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchKeyword),expression:"searchKeyword"}],attrs:{"type":"text","placeholder":_vm.$t('FORMS.Placeholders.search')},domProps:{"value":(_vm.searchKeyword)},on:{"input":function($event){if($event.target.composing)return;_vm.searchKeyword=$event.target.value}}}),_vm._m(0)]),_c('div',{staticClass:"navbar_btns_wrapper"},[_c('div',{staticClass:"group"},[_c('div',{staticClass:"theme_changer"},[_c('v-switch',{staticClass:"m-0",attrs:{"color":"#147CCC","inset":"","hide-details":"","append-icon":_vm.getAppTheme === 'light_theme' ? '☀️' : '🌕',"value":"dark_theme"},on:{"change":function($event){return _vm.changeTheme(
                  _vm.getAppTheme === 'light_theme'
                    ? 'light_theme'
                    : 'light_theme'
                )},"click:append":function($event){return _vm.changeTheme(_vm.getAppTheme)}},model:{value:(_vm.appTheme),callback:function ($$v) {_vm.appTheme=$$v},expression:"appTheme"}})],1),_c('div',{staticClass:"user_notification_content_wrapper"},[_c('transition',{attrs:{"name":"fadeInUp"}},[(_vm.notificationsMenuIsOpen)?_c('div',{staticClass:"notifications_menu_wrapper"},[_c('div',{staticClass:"notifications_menu_header"},[_c('h5',[_vm._v(" "+_vm._s(_vm.$t("TITLES.notifications"))+" ")]),_c('h3',[_vm._v(" "+_vm._s(_vm.notificationsData.unreadNotifications)+" "+_vm._s(_vm.$t("TITLES.new"))+" ")])]),(_vm.notificationsData.notificationsList.length == 0)?_c('div',{staticClass:"empty_image"},[_c('img',{attrs:{"src":require("../../assets/media/empty_messages/snooze.png"),"alt":"Empty Notifications","width":"120","height":"120"}})]):_c('ul',{staticClass:"notifications_menu"},_vm._l((_vm.notificationsData.notificationsList),function(item){return _c('li',{key:item.id,staticClass:"notifications_menu_item"},[_c('div',{staticClass:"notification_body_wrapper",on:{"click":function($event){_vm.redirectNotification(item.notify_type);
                        _vm.readSingleNotification(item.id);}}},[_c('p',{staticClass:"notification_title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',{staticClass:"notification_body"},[_vm._v(" "+_vm._s(item.body)+" ")]),_c('p',{staticClass:"notification_date"},[_vm._v(" "+_vm._s(item.created_at)+" ")])]),_c('div',{staticClass:"delete_btn_wrapper"},[_c('button',{staticClass:"delete_notification_btn",on:{"click":function($event){$event.stopPropagation();return _vm.deleteNotification({
                            id: item.id,
                            notificationType: 'notifications_menu',
                          })}}},[_c('i',{staticClass:"fal fa-trash-alt"})])])])}),0),_c('router-link',{staticClass:"all_notifications_route",attrs:{"to":"/all-notifications"}},[_vm._v(" "+_vm._s(_vm.$t("BUTTONS.allNotification"))+" ")])],1):_vm._e()])],1)]),_c('div',{staticClass:"group"},[_c('div',{staticClass:"user_profile_menu_wrapper"},[_c('button',{staticClass:"user_profile_menu_btn",on:{"click":function($event){_vm.userProfileModalIsOpen = true}}},[_c('div',{staticClass:"avatar_wrapper"},[_c('img',{attrs:{"src":require("@/assets/media/images/user_avatar.png"),"width":"25","height":"25","alt":_vm.getAuthenticatedUserData.name,"loading":"lazy"}})]),_c('div',{staticClass:"name_wrapper"},[_c('h3',[_vm._v(_vm._s(_vm.getAuthenticatedUserData.name))])])])]),_c('UserDataModal',{attrs:{"modalIsOpen":_vm.userProfileModalIsOpen},on:{"toggleModal":function($event){_vm.userProfileModalIsOpen = !_vm.userProfileModalIsOpen}}}),_c('button',{staticClass:"small_screens_navbar_toggeler",on:{"click":function($event){return _vm.$emit('fireToggleNavDrawerEmit')}}},[_c('i',{staticClass:"fal fa-bars"})])],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',[_c('i',{staticClass:"fal fa-search"})])
}]

export { render, staticRenderFns }